.file-group {
    .file-icon {
        background-color: rgba(247, 247, 247, 0.5);
        border: 1px dashed $text-muted;
    }
}
.form-file {
    position: relative;
    input {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}
.file-input {
    @include svg-bg-icon(file, $success-inverse);
    background-size: 20px;
    background-position: right 10px center;
}
.file-input + input[type="file"] {
    opacity: 0;
    position: absolute;
    // margin-top: -33px;
    transform: translateY(-100%);
}

html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

//added by sonal 16-05-2022
@function toRem($value) {
  $remValue: ($value / 16) + rem;
  @return $remValue;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $text-muted !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $text-muted !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $text-muted !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $text-muted !important;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  //font-size: $root-font-size !important;
  font-weight: $font-weight-base;
  font-family: $font-family-sans-serif;

  // Tablet mode
  @include media-breakpoint-down(lg) {
    font-size: $root-font-size-lg !important;
  }

  // Mobile mode
  @include media-breakpoint-down(md) {
    font-size: $root-font-size-md !important;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none !important;
  }
}

html {
  font-size: 16px !important;
}

body {
  display: flex;
  flex-direction: column;
  color: $body-color;
  font-size: toRem(14) !important;
  line-height: toRem(20);
}

// Angular integration
router-outlet {
  display: none;
}

canvas {
  user-select: none;
}

// custom css added by sonal
.form-label {
  font-size: toRem(14);
  line-height: toRem(20);
  font-weight: 400;
  color: $body-color;
}

.form-control {
  padding: toRem(7) toRem(10);
  font-size: toRem(14);
  border-radius: toRem(6);
  color: $body-color;
  font-weight: 400;
  &:disabled {
    background-color: #f7f7f7;
  }
}

.form-select {
  color: $body-color;
  font-weight: 400;
}
.dropdown {
  font-size: toRem(14);
}
.form-search {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20opacity%3D%220.5%22%20x%3D%2217.0365%22%20y%3D%2215.1223%22%20width%3D%228.15546%22%20height%3D%222%22%20rx%3D%221%22%20transform%3D%22rotate(45%2017.0365%2015.1223)%22%20fill%3D%22currentColor%22%2F%3E%3Cpath%20d%3D%22M11%2019C6.55556%2019%203%2015.4444%203%2011C3%206.55556%206.55556%203%2011%203C15.4444%203%2019%206.55556%2019%2011C19%2015.4444%2015.4444%2019%2011%2019ZM11%205C7.53333%205%205%207.53333%205%2011C5%2014.4667%207.53333%2017%2011%2017C14.4667%2017%2017%2014.4667%2017%2011C17%207.53333%2014.4667%205%2011%205Z%22%20fill%3D%22currentColor%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: left 7px center;
  background-size: 24px 24px;
  padding-left: 40px;
  height: 36px;
}

.v-seperator {
  height: 36px;
  width: 1px;
  background-color: $bg-light;
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
}

.b-seperator {
  background-color: $text-muted;
  width: 12px;
  height: 1px;
}

// font size start
.font-10 {
  font-size: toRem(10);
}

.font-13 {
  font-size: toRem(13);
  line-height: toRem(19);
}

.font-14 {
  font-size: toRem(14);
  line-height: toRem(20);
}

.font-15 {
  font-size: toRem(15);
  line-height: toRem(19);
}

.body-font,
.font-16 {
  font-size: toRem(16);
  line-height: toRem(22);
}

.font-17 {
  font-size: toRem(17) !important;
  line-height: toRem(25);
}

.font-20 {
  font-size: toRem(20);
  line-height: toRem(30);
}

.font-24 {
  font-size: toRem(24);
  line-height: toRem(32);
}

.font-28 {
  font-size: toRem(28);
  line-height: toRem(34);
}

.font-30 {
  font-size: toRem(30);
  line-height: toRem(38);
}

// font size end

//svg icon size start
.svg-icon {
  svg {
    height: toRem(24);
    width: toRem(24);
  }
}

.ic {
  &.mr-0 {
    margin-right: 0;

    svg {
      margin-right: 0;
    }
  }
}

//svg icon size end

/*font family start*/
.font-regular,
.fw-400 {
  font-weight: 400;
}

.font-bold,
.fw-700,
.fw-bold {
  font-weight: 700 !important;
}

.font-semibold,
.fw-600 {
  font-weight: 600;
}

.fw-500,
.font-medium {
  font-weight: 500 !important;
}

/*font family end*/
// utilities
.bg-muted {
  background-color: $text-muted;
}

.p-7 {
  padding: toRem(30) !important;
}

.px-7 {
  padding-right: toRem(30) !important;
  padding-left: toRem(30) !important;
}

.mb-6 {
  margin-bottom: toRem(20) !important;
}

.symbol-36px {
  img {
    width: 36px;
    height: 36px;
  }
}

.w-lg-470px {
  width: 100% !important;

  @include media-breakpoint-up(lg) {
    width: 470px !important;
  }
}
.text-hover-decoration-underline {
  &:hover {
    text-decoration: underline !important;
  }
}
.logo {
  height: 85px;

  @include media-breakpoint-up(lg) {
    height: 150px;
  }
}

.card-toolbar {
  .form-select {
    height: 36px;
  }

  .btn {
    height: 36px;
  }
}
.btn {
  line-height: 1.25rem;
}
.card {
  .card-header {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.01);
    border-bottom: 0;
    padding-left: toRem(30);
    padding-right: toRem(30);

    .card-title {
      font-size: toRem(17);

      h3 {
        font-size: toRem(17);
        font-weight: bold !important;
      }
    }
  }

  .card-body {
    padding-left: toRem(30);
    padding-right: toRem(30);
  }
  .card-footer {
    padding-left: toRem(30);
    padding-right: toRem(30);
  }
}

.modal-title {
  font-size: toRem(17);
  line-height: toRem(25);
}

.modal-body {
  padding-top: toRem(20);
  padding-bottom: toRem(20);
}

.modal-footer {
  padding: toRem(7.5) toRem(15);
}

.modal-footer > * {
  margin: toRem(7.5) !important;
}

.calendar {
  @include svg-bg-icon(date, $success-inverse);
  background-size: 20px;
  background-position: right 10px center;
  padding-right: toRem(40);
}

.dropdown-toggle {
  padding-right: toRem(40) !important;
  font-weight: 400;
}

.dropdown-toggle::after {
  border: 0;
  height: 22px;
  width: 24px;
  @include svg-bg-icon(arrow-bottom, $text-muted);
  background-size: 13px;
  position: absolute;
  background-position: right;
}

// table css
.table {
  &.gy-3 {
    th {
      padding-top: toRem(10);
      padding-bottom: toRem(10);
    }

    td {
      padding-top: toRem(10);
      padding-bottom: toRem(10);
    }
  }
}

.page-link {
  padding: toRem(5);
}

.action-th {
  width: 100px;
  text-align: right;
}

.badge {
  padding: toRem(5) toRem(10);
  font-size: toRem(13);
  font-weight: 600;
  line-height: toRem(19);
}

@include media-breakpoint-down(lg) {
  .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .wrapper {
    padding-top: 55px !important;
  }
}

// warehouse css
.wh-list {
  li {
    cursor: pointer;

    &:last-child {
      border-bottom: 0px !important;
      margin-bottom: 0 !important;
    }
  }
}

//pro icon

.pro-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.top-search {
  @include media-breakpoint-down(sm) {
    // width: 220px;
    display: none;
  }
}

.badge-light {
  background-color: #e1e2e2;
}

// temp css
.modal-backdrop.fade.show + .modal-backdrop {
  z-index: 10523;
}

div#modal_img_crop {
  z-index: 1055555;
}
.modal-backdrop.fade.show + .modal-backdrop + .fade.modal.show {
  z-index: 105555;
}
//daterangepicker default css start
.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
  .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges,
  .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
//daterangepicker default css end

//daterangepicker custom scss start

.daterangepicker {
  padding: 0;
  margin: 0;
  border: 0;
  width: auto;
  background-color: $body-bg;
  box-shadow: $dropdown-box-shadow;
  font-family: $font-family-sans-serif;
  // z-index: $zindex-dropdown;
  z-index: 10001;
  @include border-radius($dropdown-border-radius);

  &:after,
  &:before {
    display: none;
  }

  td {
    &.off,
    &.off.in-range,
    &.off.start-date,
    &.off.end-date {
      background-color: transparent;
    }
  }

  .modal-open & {
    z-index: $zindex-modal + 1;
  }

  .calendar-table {
    background-color: $body-bg;
    border: 0;
  }

  .ranges {
    @include border-radius($dropdown-border-radius);
    background-color: $body-bg;

    ul {
      padding: 1rem 0;
      width: 175px;
    }

    li {
      padding: 0.7rem 1.75rem;
      font-weight: 500;
      font-size: toRem(14);
      color: $gray-600;
      transition: $transition-link;

      &:hover {
        background-color: $component-hover-bg;
        color: $component-hover-color;
        transition: $transition-link;
      }

      &.active {
        background-color: $component-active-bg;
        color: $component-active-color;
        transition: $transition-link;
      }
    }
  }

  &.show-calendar {
    .ranges {
      @include border-radius(0);
      @include border-top-start-radius($dropdown-border-radius);
      margin-top: 0;
      height: 297px;
    }
  }

  &.show-ranges {
    &.show-calendar {
      .ranges {
        border-right: 1px solid $gray-200;
      }
    }

    .drp-calendar.left {
      border-left: 0;
    }
  }

  .drp-buttons {
    padding: 1rem 1.75rem;
    border-top: 1px solid $gray-200;

    .btn {
      font-size: toRem(14);
      font-weight: $font-weight-bold;
      padding: 0.5rem 1rem;
    }

    .cancelBtn {
      $color: $light-inverse;
      $icon-color: $light-inverse;
      $border-color: $light;
      $bg-color: $light;

      $color-active: $light-inverse;
      $icon-color-active: $light-inverse;
      $border-color-active: $light-active;
      $bg-color-active: $light-active;

      @include button-custom-variant(
        $color,
        $icon-color,
        $border-color,
        $bg-color,
        $color-active,
        $icon-color-active,
        $border-color-active,
        $bg-color-active
      );
    }
  }

  .drp-selected {
    font-size: 0.9rem;
  }

  .drp-calendar {
    &.left,
    &.right {
      padding: 1rem 1rem;
    }

    &.left {
      border-left: 0 !important;
    }

    th,
    td {
      font-size: toRem(14);
      font-weight: $font-weight-normal;
      width: 33px;
      height: 33px;

      &.available:hover {
        @include border-radius($border-radius);
        background-color: $component-hover-bg;
        color: $component-hover-color;
      }
    }

    th {
      font-weight: $font-weight-bold;
      color: $gray-800;

      &.month {
        font-weight: $font-weight-bold;
        color: $gray-800;
      }

      &.next,
      &.prev {
        span {
          border-width: 0 1px 1px 0;
          border-color: $gray-600;
        }

        &.available:hover {
          span {
            border-color: $component-hover-color;
          }
        }
      }

      &.next {
        span {
          margin-right: 1px;
        }
      }

      &.prev {
        span {
          margin-left: 1px;
        }
      }
    }

    td {
      color: $gray-700;

      &.available.off {
        color: $gray-400;
      }

      &.active {
        background-color: $component-active-bg !important;
        color: $component-active-color !important;
        @include border-radius($border-radius);

        &.start-date {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.end-date {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.start-date.end-date {
          @include border-radius($border-radius);
        }
      }

      &.today,
      &.today.active {
        background: $component-hover-bg !important;
        color: $component-hover-color !important;
        @include border-radius($border-radius);
      }

      &.in-range.available:not(.active):not(.off):not(.today) {
        background-color: $component-hover-bg;
        color: $component-hover-color;
      }

      &:hover {
        background-color: $component-hover-bg;
        color: $component-hover-color;
      }
    }
  }

  select.ampmselect,
  select.minuteselect,
  select.hourselect,
  select.monthselect,
  select.yearselect {
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    @include border-radius($border-radius);
    background-color: $body-bg !important;
    border-color: transparent;
    color: $input-color;
    font-weight: $font-weight-bold;
    outline: 0 !important;

    &:focus {
      background-color: $gray-100;
    }
  }
}

// Tablet mode
@include media-breakpoint-down(md) {
  .daterangepicker {
    &.show-calendar {
      .ranges {
        float: none !important;
        height: auto !important;

        ul {
          width: 100%;
        }
      }

      .drp-calendar {
        float: none !important;
        max-width: unset !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
// react custom daterangepicker scss end

//react single datepicker css start
.react-datepicker {
  padding: 0;
  border: 0;
  .react-date-picker__wrapper {
    border: 1px solid $border-color;
    padding: 0.4375rem 0.625rem;
    font-size: 0.875rem;
    border-radius: 0.375rem;
    padding-right: toRem(40);
  }
  .react-date-picker__inputGroup {
    padding: 0;
  }
}
.react-date-picker__inputGroup__input {
  &:focus {
    outline: none;
  }
}
.react-date-picker__calendar {
  max-width: 270px !important;
  z-index: 10001 !important;
  width: 270px !important;
  .react-calendar {
    padding: 0;
    margin: 0;
    border: 0;
    width: auto;
    border: 0;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    z-index: 10001 !important;
    border-radius: 0.375rem;
    .react-calendar__month-view__days {
      button {
        font-size: 0.875rem;
        font-weight: 400;
        width: 33px;
        height: 33px;
        min-width: 32px;
      }
    }
  }
}
.react-calendar__navigation {
  button {
    min-width: 32px !important;
  }
}
.react-calendar__year-view {
  .react-calendar__tile {
    padding: 1.5em 0.5em !important;
  }
}

.pro-banner {
  position: relative !important;
  + .form-file {
    position: static !important;
    input {
      bottom: 27px;
    }
  }
}

// react select
.react-select-container {
  .react-select__control {
    border-color: $light;
    min-height: 36px;
    box-shadow: none;
    border-radius: toRem(6);
    &:hover {
      border-color: $light;
    }
    // &:focus {
    //   border-color: $primary;
    // }
    &.react-select__control--is-focused {
      border-color: $primary;
    }
    .react-select__value-container {
      padding-left: toRem(10);
      padding-right: toRem(10);
    }
  }
  .react-select__indicator {
    padding: 6px;
    color: #111;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__multi-value {
    border-radius: 0.42rem;
    flex-direction: row-reverse;
    background-color: $light;
    .react-select__multi-value__label {
      padding-left: 0;
      padding-right: 10px;
    }
    .react-select__multi-value__remove:hover {
      color: inherit;
      background-color: transparent;
    }
  }

  .react-select__menu {
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
    margin-top: 5px;
    font-size: toRem(14);
    .react-select__menu-list {
      font-size: toRem(14);
      padding: 0;
      .react-select__option {
        padding: 0.5125rem 0.875rem;
        font-size: toRem(14);
        &:first-child {
          border-top-left-radius: 0.325rem;
          border-top-right-radius: 0.325rem;
        }
        &:last-child {
          border-bottom-left-radius: 0.325rem;
          border-bottom-right-radius: 0.325rem;
        }
        // &:hover {
        //   background-color: $pri;
        // }
      }
    }
  }
}

.btn-close {
  // background-position: right 10px center;
  background-image: url("data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20opacity%3D%220.3%22%20d%3D%22M6.7%2019.4L5.3%2018C4.9%2017.6%204.9%2017%205.3%2016.6L16.6%205.3C17%204.9%2017.6%204.9%2018%205.3L19.4%206.7C19.8%207.1%2019.8%207.7%2019.4%208.1L8.1%2019.4C7.8%2019.8%207.1%2019.8%206.7%2019.4Z%22%20fill%3D%22currentColor%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M19.5%2018L18.1%2019.4C17.7%2019.8%2017.1%2019.8%2016.7%2019.4L5.40001%208.1C5.00001%207.7%205.00001%207.1%205.40001%206.7L6.80001%205.3C7.20001%204.9%207.80001%204.9%208.20001%205.3L19.5%2016.6C19.9%2016.9%2019.9%2017.6%2019.5%2018Z%22%20fill%3D%22currentColor%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  height: 24px;
  width: 24px;
  opacity: 1;

  &:focus {
    box-shadow: none;
  }
}

//table {
.input-group {
  border: 1px solid $light;
  border-radius: toRem(6);
  .input-group-text {
    border: 0;
    border-color: $primary !important;
  }
  input {
    border: 0;
    border-color: $primary !important;
  }
  &:focus-within {
    border-color: $primary !important;
  }
}
//}

//top menu custom
.menu-bordered {
  .menu-sub {
    .menu-item {
      border-bottom: 1px solid $light;
    }
  }
}
.ms-n15px {
  margin-left: -15px;
}
